.Container {
    background-color: #393ae7; 
    border: none;
    color: white;
    text-decoration: none;
    border-radius: 8px; 
    outline: none; 
}

.Button:disabled {
    opacity: 0.3; 
    cursor: not-allowed; 
    background-color: #393ae7; 
}

@media (any-hover: hover) and (pointer: fine) {
    .Container:hover {
        background-color: #5355d4; 
        cursor: pointer; 
        outline: none; 
    }
}
