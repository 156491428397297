.Container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 18px;
}

.Image {
    border: 6px solid #2b6d83;
    width: 160px;
    object-fit: cover;
}

.Name {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
}

.Role {
    margin: 0;
    font-size: 16px;
}
