.Container {
    width: 100%; 
    height: 100%; 
    z-index: 1000;
    position: fixed; 
    left: 0;
    top: 0; 
    background-color: rgba(0, 0, 0, 0.3); 
    display: none;
}

.Show {
    display: block;
}
