.Container {
    margin: auto;
    width: 80%;
    max-width: 1000px;
}

.Container + .Container {
    margin-top: 50px;
}

.Title {
    font-size: 30px; 
    font-weight: 800; 
}

.Divider {
    background-color: white;
}

.Body {
    font-size: 18px;
    line-height: 34px; 
    text-align: justify;
}

.Content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Image {
    margin-top: 10px;
    width: 200px;
    border-radius: 16px;
}

@media only screen and (min-width: 768px) {
    .Content {
        align-items: start;
        flex-direction: row;
    }
    .Image {
        margin-top: 28px;
        margin-left: 40px;
    }
}

