.Container {
    padding: 50px;
}

.Title {
    text-align: center; 
    font-size: 64px;
    font-weight: 800;
}

.Description {
    text-align: center; 
}

.HomeButton {
    display: block;
    margin: 36px auto 0; 
    width: 100%;
    max-width: 200px;
    font-size: 14px;
    padding: 10px;
}

@media only screen and (min-width: 768px) {
    .Title {
        font-size: 96px;
    }

    .Description {
        font-size: 20px;
    }

    .HomeButton {
        font-size: 16px;
        padding: 12px;
    }
}
