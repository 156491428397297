.Container {
    position: fixed;
    width: 270px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1050; 
    box-sizing: border-box;
    padding: 16px 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out; 
}

.Show {
    transform: translateX(0);
}

.Header {
    padding: 0 16px;
}

.NavItems {
    width: 100%;
}

.SidebarButton {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HamburgerLine {
    height: 2px;
    width: 16px;
    background-color: white;
}

.HamburgerLine + .HamburgerLine {
    margin-top: 4px;
}

.HeaderNavDivider {
    height: 1px;
    background-color: gainsboro;
    margin: 16px 0;
    border-width: 0;
    width: 100%;
}

.Footer {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    padding: 0 16px;
}
